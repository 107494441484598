import FeatureGates from '@atlaskit/feature-gate-js-client';

const getIsAdvancedSearchInTreeEnabled = () => {
	return (
		FeatureGates.getExperimentValue<'test' | 'control'>(
			'advanced_search_in_tree',
			'cohort',
			'control',
		) === 'test'
	);
};

export const useIsAdvancedSearchInTreeEnabled = (): {
	isAdvancedSearchInTreeEnabled: boolean;
} => {
	const isAdvancedSearchInTreeEnabled = getIsAdvancedSearchInTreeEnabled();
	return { isAdvancedSearchInTreeEnabled };
};
